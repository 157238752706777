import "../../libs/components/src/organisms/meal-selections/confirm-selections-container.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/organisms/meal-selections/confirm-selections-container.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61U/26bMBD+P09hdYpotEAMtE1KFWl9g73B5OCDWDU2sk1DO+XddxiSkKRbJ23iD+z78d1357uLfsTFwztVlPycELLRhoPJSFy3xGopONlIlr88oapmnAtVos5A1Qm2IMqty8iK0rrtBPoVTCH1LiM2N1rKp8l+Eg3wsYffCe62iEDpdKxMPov9h1BbwTmoMVrq0UojeOigqiVzEOZaNpWyGTFQA3O3yZzEhZl1UFxYtHnLvEcn6G3DktUZSYdcK2ZKoUKnUZZ42SnenY83tjh4/Sbde+9QaOVCK97hgDiIClYJiXRY3lQIWBsdWqgEpqAwUQt8Tp6NYHJOLFMWdUYUI4ob7Zyurlk++KAOWhc6g46FNmjV1DWYnFn45/CjdOJoeVa2vmUI7b6zTqJjgktPEIuvsQe+pJslX7IrVsFz7nmR70YHf8MkOX/AQ3VodH9Rn9WH9clZLRyTCPb/qFyneEEuvqD22I8HzkJpdKN4KCpWImRj5O0NZ45lXrCwr+XXtpLzaZrjse++ddB1X3IfDDN0uqOlsutg61ydLRa73S7apZE25SKhlHZYwTQFhDKQu8+wCiHlOlBaQUCsM/oF1oEf3sM1HADSo4Azu2XGsLd1cDdNcrI60+iisIAB6FEqhQJ8DcRtnAsWPbeOJp5uZlfTdtoZcb8xLlox8b+n0drxzfitAi5Yt78AFGGKk9uKteEAvXp8rNuZf43obG2eorHG6Q51P7JJBpsLgienu8NaG3ulg9dxPxUS2t6vO4VcdC8jNObUb6xe59fWodXO0+4j7H8BYyxXQvIFAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var confirmSelectionsContainer = '_1f6z0n0';
export var confirmSelectionsGrid = '_1f6z0n3';
export var confirmSelectionsImage = '_1f6z0n2';
export var container = '_1f6z0n4';
export var countHeader = '_1f6z0n8';
export var divider = '_1f6z0n9';
export var goAheadAndSubmit = '_1f6z0n7';
export var header = '_1f6z0n5';
export var imgTags = '_1f6z0n1';
export var summaryHeader = '_1f6z0n6';