import "../../libs/components/src/organisms/meal-selections/confirm-delivery.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/organisms/meal-selections/confirm-delivery.css.ts.vanilla.css\",\"source\":\"Ll8xYnh6Z3psMCB7CiAgZm9udC1mYW1pbHk6IGFjdW1pbi1wcm8tc2VtaS1jb25kZW5zZWQsIEFyaWFsLCBzYW5zLXNlcmlmOwp9Ci5fMWJ4emd6bDEgewogIG1hcmdpbi1ib3R0b206IDJyZW07Cn0KLl8xYnh6Z3psMiB7CiAgZm9udC1mYW1pbHk6IGFjdW1pbi1wcm8tc2VtaS1jb25kZW5zZWQsIEFyaWFsLCBzYW5zLXNlcmlmOwogIHdpZHRoOiAxMDAlOwogIGJhY2tncm91bmQtY29sb3I6ICNkNGY5ZTM7CiAgcGFkZGluZzogMC4zcmVtIDAuNXJlbTsKfQouXzFieHpnemwzIHsKICBtYXJnaW4tbGVmdDogMC41cmVtOwp9Ci5fMWJ4emd6bDQgewogIGZvbnQtZmFtaWx5OiBhY3VtaW4tcHJvLXNlbWktY29uZGVuc2VkLCBBcmlhbCwgc2Fucy1zZXJpZjsKICBsaXN0LXN0eWxlOiBub25lOwogIGNvbG9yOiByZWQ7CiAgZm9udC1zdHlsZTogaXRhbGljOwp9Ci5fMWJ4emd6bDUgewogIGZvbnQtZmFtaWx5OiBhY3VtaW4tcHJvLXNlbWktY29uZGVuc2VkLCBBcmlhbCwgc2Fucy1zZXJpZjsKICBsaXN0LXN0eWxlOiBub25lOwogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGJhc2VsaW5lOwp9Ci5fMWJ4emd6bDYgewogIGJvcmRlci1yYWRpdXM6IDUwJTsKICBib3JkZXI6IDFweCBzb2xpZCBibGFjazsKICBtYXJnaW4tcmlnaHQ6IDAuNXJlbTsKICB3aWR0aDogMzBweDsKICBoZWlnaHQ6IDMwcHg7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwp9Ci5fMWJ4emd6bDcgewogIHBhZGRpbmctdG9wOiAwLjI1cmVtOwp9Ci5fMWJ4emd6bDggewogIHRleHQtdHJhbnNmb3JtOiBjYXBpdGFsaXplOwp9Ci5fMWJ4emd6bDkgewogIGZvbnQtZmFtaWx5OiBhY3VtaW4tcHJvLXNlbWktY29uZGVuc2VkLCBBcmlhbCwgc2Fucy1zZXJpZjsKICBtYXJnaW46IDFyZW0gMDsKICBwYWRkaW5nOiAwOwp9\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var deliveryContainer = '_1bxzgzl1';
export var deliveryNumberHeader = '_1bxzgzl2';
export var header = '_1bxzgzl0';
export var itemCount = '_1bxzgzl6';
export var itemCountNumber = '_1bxzgzl7';
export var mealSelectionLi = '_1bxzgzl5';
export var mealTitle = '_1bxzgzl8';
export var noMealsLi = '_1bxzgzl4';
export var sectionContainer = '_1bxzgzl3';
export var sectionHeader = '_1bxzgzl9';