import "../../libs/components/src/organisms/meal-selections/initial-selections.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/organisms/meal-selections/initial-selections.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VUy27bMBC8+ysWKAKnqGnI8kOxfGmAtMeiKPIDFEnLTClSoKjIaeF/L0k9LNlygJx6MrzcnZ3ZndWcvxQ0CgP4OwHYK2nQHmdcvMXAkwzlgh1RwTTfz2D6zDNWwA9WwS+VYTmdgX/Z2ULBJUMHxtODiSFc5kcXzDGlXKYoUcaoLIZgvtYs201Ok3nddOGbZlinXCKj8hhWPqEhUjV4UukMi15d2K9r0cMhdghJaR/kHBPDX5mvSDD5nWpVSoqIEkrH8GkRbegmci3byNPq+/bbcgTJQwyELqJaaHXghqEix4Q5tpXGuQsnSlOmkcaUl0UM66DOrnlbrfVfL7Xgf2zpYh6O6U+UoC5o2NEgyojS2HAlXSvJeoN2E44sQDNoIFiQ+yb0BcL8+Pl6Bct6MJ6pJZAfoVCCU0iEndU7IigvcoGtS/bWIT281dlHtaZW0ei2LhyHSZnZnFyrGTxqjoV1GJYF6mx20RUgxdY0iwYLC55KZFeRWaKEScO0C7+UheH7N7tyG5Hm/NSRXn+Q9E3H/m8hm942O9YjS+0LaEWNmriH3DuC1sHBqCuD3TWHYGjTEeDBnQ6LV50Ad65P9bneOIYO98EjvTfnLnXrU9v7XyYRjfDVSqePxC8Vfmo1vbHV0VO+8NDVBWLfveLUHGxZELxWvcdkqCLV3H8J3C+y9rBRuzPLvMyktUoU3MEyuKu7HlGLudr4oz1NvmaMcgwF0YxJwJLCfS/vYbt1nwjXcHDM4IeHKNeM1KOuG7o2p3Py+iPJuEnulCVC1dbsJSU3k3peam/mNDn9Ayqp7MLLBgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var chooseDayHeader = 'ijsd725';
export var container = 'ijsd721';
export var daySelectorButtonBox = 'ijsd723';
export var daySelectorRow = 'ijsd722';
export var gridParent = 'ijsd72a';
export var guidanceText = 'ijsd720';
export var header = 'ijsd724';
export var headerButtons = 'ijsd728';
export var headerText = 'ijsd727';
export var planTabRow = 'ijsd726';
export var tabGrid = 'ijsd72b';
export var youNeedToChoose = 'ijsd729';